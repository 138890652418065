@use "assets/styles/global" as *;

.header {
  background-color: $primary-color;
  height: 105px;
  position: relative;
  width: 100%;
  width: -webkit-fill-available;

  .smiley {
    display: none;
  }

  .inner {
    align-items: center;
    background-color: inherit;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    position: relative;
  }
}

.header-section {
  flex-grow: 1;
}

.minicart-section {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 1rem;

  & > * + * {
    margin-left: 1rem;
  }
}

.mobile-sidebar-trigger {
  // The button in the mobile header to show the sidebar

  .mobile-sidebar-button {
    align-items: center;
    display: flex;
    justify-content: center;

    font-size: 48px;
    font-weight: normal;

    color: $sidebar-text-color;
    background: transparent;

    height: 42px;
    width: 42px;

    padding: 0;
    margin-bottom: 7px;

    border: none;
    user-select: none;
    outline: none;

    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}
