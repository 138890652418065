@use "assets/styles/global" as *;

.input-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  .input-item {
    margin: 0;
    margin-bottom: 2px;
    flex-grow: 1;
    min-width: 49%;

    /* Spacing on uneven children */
    &:nth-child(odd) {
      margin-right: 2%;
    }

    /* Except the last one */
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }

  &.full {
    .input-item {
      width: 100%;
    }
  }
}

.input-error {
  display: none;
}

.forced-input-error {
  display: block;
}

.show-validation-errors {
  .input-error {
    display: block;
  }
}
