@use "assets/styles/global" as *;

.orderlist-item {
  align-items: center;
  column-gap: 0.75rem;
  display: grid;
  font-size: 14px;
  grid-template-columns: auto 8fr 3fr 1fr;
  justify-content: space-between;
  position: relative;
  padding: 0.4rem 0;

  &.disabled .product-name {
    grid-column: span 2 / span 2;
  }

  .product-name {
    text-transform: uppercase;
  }

  .product-price {
    text-align: right;
    text-transform: uppercase;
  }

  button.button-muted {
    font-size: 12px;
  }
}

.orderlist-item-col {
  & > span {
    display: block;
  }
}

.orderlist-item-choice-list {
  font-weight: normal;
  font-family: $secondary-font;
  list-style: none;
  margin: 0;
  padding: 0.25rem 0;
  font-size: 14px;

  li {
    line-height: 1.4;
  }

  &.choices-price li {
    text-align: right;
  }

  .empty-choice-price {
    height: 20px;
  }
}
