@use "assets/styles/global" as *;

.orderlist {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 1rem;
  width: 100%;
}

.empty-orderlist {
  text-align: center;
}
