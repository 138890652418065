@use "assets/styles/global" as *;

.guest-info-content.input-form {
  .input-item {
    margin-right: 0;

    input {
      text-transform: uppercase;
    }
  }

  .input-item:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}
