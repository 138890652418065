@use "assets/styles/global" as *;

.terms-iframe {
  align-items: center;
  background-color: $terms-iframe-background;
  bottom: 0;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: $iframe-z-index;

  .terms-iframe-wrapper {
    background-color: $background-color;
    height: 80%;
    max-height: 1000px;
    position: relative;
    width: 80%;
  }

  iframe {
    height: 100%;
    width: 100%;
  }

  .exit-btn {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 0.5rem;

    .icon {
      display: block;
      height: 1rem;
      width: 1rem;
    }
  }
}
