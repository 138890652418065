@use "assets/styles/global" as *;

.simple-total {
  border: 1px solid $simple-total-border-color;
  border-left: none;
  border-right: none;
  box-sizing: border-box;
  display: flex;
  font-size: 22.5px;
  font-weight: bold;
  justify-content: space-between;
  padding: 0.5rem 0;
  width: 100%;
}
