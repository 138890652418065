@use "assets/styles/global" as *;

.choice-list {
  padding: 0;
  margin-top: 1rem;
  max-width: 65%;
}

.choice {
  align-items: center;
  background-color: $choice-background-color;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  margin-bottom: 2px;
  max-height: 100%;
  padding: 7px 11px;

  &.active {
    .choice-name,
    .counter-text-number {
      color: $choice-active-text-color;
    }
  }

  .choice-name,
  .choice-price {
    font-weight: bold;
    margin: 0;
  }

  .choice-name {
    flex-grow: 1;
  }

  .choice-price {
    width: 15%;
  }
}

.choice-list-collapse {
  display: flex;
  justify-content: center;

  .collapsible-icon {
    margin-top: 0.5rem;
    width: 2rem;
    height: 2rem;
  }
}
