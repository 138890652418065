@use "assets/styles/global" as *;

.logo-container {
  align-items: center;
  justify-content: center;
  display: flex;
}

.logo {
  cursor: pointer;
  max-width: 70%;
  max-height: 8rem;
}
