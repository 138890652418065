@use "assets/styles/global" as *;

.precheckout-content {
  border-radius: 8px;
  padding-top: 1rem;

  & > * + * {
    margin-top: 0.5rem;
  }

  .precheckout-section {
    header {
      align-items: center;
      background-color: white;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem;
      width: 100%;
    }

    &:last-child header {
      padding: 1.25rem 1rem;
    }
  }

  .precheckout-subtitle {
    font-size: 26px;

    span {
      font-family: $accent-font;
    }
  }

  .simple-total {
    padding: 0.5rem 0;
  }
}

.precheckout-products {
  background-color: $precheckout-products-background-color;
  padding: 1rem;

  .precheckout-products-title {
    align-items: baseline;
    color: $modifier-title-text-color;
    font-family: $accent-font-italic;
    font-weight: normal;
    font-size: 17px;
    display: flex;
    flex-direction: row;
    padding-right: 2rem;
    position: relative;

    .count {
      color: $modifier-counter-text-color;
      font-family: $accent-font;
      font-size: 26px;
      line-height: 1.05;
      margin-right: 1rem;
    }
  }

  .product-list {
    margin: 0;
    padding: 1rem 0;

    .product {
      margin: 0;
      margin-top: 2px;
    }
  }

  .product {
    box-shadow: none;
    padding: 7px 11px;
  }

  .product-content {
    .product-image {
      display: none;
    }

    .product-details {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 0;

      &.active {
        .product-name,
        .product-price,
        .product-price-text,
        .counter-text-number {
          color: $precheckout-products-active-text-color;
        }
      }
    }

    .product-name-container {
      .product-name {
        color: $precheckout-products-text-color;
        font-size: 12px;
        line-height: normal;
      }

      .product-description {
        display: none;
      }
    }

    .product-action-container {
      border: none;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .product-price,
    .product-price .product-price-text {
      color: $precheckout-products-text-color;
      font-size: 12px;
    }

    .product-price {
      margin: 0 1rem 0 0;
    }

    .counter {
      .counter-text-extra {
        display: none;
      }
    }
  }

  .product-cta-buttons {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding: 1.5rem 0;
  }
}
