@use "assets/styles/global" as *;

.product-list {
  display: grid;
  column-gap: 0.5rem;
  margin-top: 1rem;
  row-gap: 1rem;
}

.product {
  animation-duration: 500ms;
  animation-name: fadein;
  background-color: $product-background-color;

  overflow: hidden;

  &.blocked {
    display: none;
    pointer-events: none;
  }
}

.product-content {
  display: flex;
  flex-direction: row;

  .product-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-shrink: 0;
    width: 20%;
  }

  .product-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    flex-grow: 1;

    &.active {
      .counter-text-number {
        color: $product-active-counter-number-text-color;
      }
    }

    button {
      align-items: baseline;
      display: flex;
      font-size: 16px;
      white-space: nowrap;

      &.active {
        background-color: $product-active-button-background-color;
        border-color: $product-active-button-border-color;

        span {
          transform: rotate(180deg);
        }
      }

      span {
        background-repeat: no-repeat;
        height: 12px;
        margin-left: 0.25rem;
        width: 12px;
      }
    }

    .product-price-sale {
      color: $product-sale-price-text-color;
      font-weight: bold;
      margin: 0;
      text-align: right;

      span {
        margin-left: 0.25rem;
      }
    }
  }

  .product-name-container {
    text-align: left;
    padding-right: 1rem;

    .product-name {
      font-weight: 900;
      font-size: 28px;
      color: $product-title-color;
      word-break: break-word;
    }

    .product-subtitle-container {
      align-items: center;
      display: flex;
    }

    .product-subtitle {
      color: $product-subtitle-color;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .labels {
      padding: 0;
      padding-left: 0.5rem;
    }

    .product-description {
      color: $product-text-color;
      font-family: $secondary-font;
      font-size: 14.5px;
      line-height: 14px;
      margin: 0.25rem 0 0 0;
      white-space: pre-line;
    }
  }

  .product-action-container {
    border-left: 1px dashed $border-color;
    flex-shrink: 0;
    padding-left: 16px;
    padding-top: 16px;
    width: 13rem;
  }

  .product-price {
    color: $product-title-color;
    font-size: 64px;
    font-weight: 600;
    text-align: right;

    .product-price-text {
      color: inherit;
      font-size: 20px;
      margin-left: 0.3rem;
    }
  }

  .button {
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: center;
    padding: 0.4rem;
    width: 100%;

    &:focus {
      outline: none;
    }

    .collapsible-icon {
      line-height: 0;
      margin-left: 5px;
    }
  }
}

/* No modifiers */
.product-list.grid {
  grid-template-columns: repeat(3, 1fr);

  .product-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .product-image {
    aspect-ratio: 1 / 1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-shrink: 0;
    min-width: 100%;
    width: 50%;
  }

  .product-name {
    font-size: 24px;
  }

  .product-details {
    display: flex;
    flex-direction: column;
  }

  .product-name-container {
    box-sizing: border-box;
    flex-grow: 1;
    padding: 0;
  }

  .product-description {
    font-size: 14px;
  }

  .product-action-container {
    box-sizing: border-box;
    border-left: 0;
    min-width: 100%;
    padding-left: 0;
  }

  .product-price {
    color: $product-no-modifier-price-text-color;
    font-size: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    padding-top: 2rem;
    padding-bottom: 0.25rem;
  }

  .counter-container {
    border-top: 1px dashed $border-color;
    padding-top: 0.75rem;
  }

  .counter-text-number {
    font-size: 16px;
  }
}
