@use "assets/styles/global" as *;

.smiley {
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: block;
  height: 24px;
  width: 20px;

  img {
    height: 100%;
    width: 100%;
  }
}
