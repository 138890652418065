.page-view {
  background-color: $background-color;
  padding-bottom: 2rem;
  width: 100%;
}

.inner {
  box-sizing: border-box;
  color: $text-color;
  height: 100%;
  padding: 0 1rem;
  width: 100%;
}

// Layout for any pages with no other content than a status - e.g. error, maintenance, order, confirmation

.status-page {
  .status-page-text {
    width: 100%;
    text-align: center;
  }

  .button {
    display: block;
    margin: 1rem auto;
  }
}

.content-body {
  width: 100%;

  &.sidebar-body {
    // Content with the sidebar on the left
    display: flex;
    flex-direction: column-reverse;
    width: 100%;

    & > div:nth-child(1) {
      width: 0;
    }

    & > div:nth-child(2) {
      width: 100%;
      flex-grow: 1;
    }
  }
}

// Accordion style with rotating icon
.collapsible-section {
  &.expanded {
    .collapsible-icon {
      animation-duration: 500ms;
      animation-name: rotate;
      transform: rotate(180deg);
    }

    .collapsible-content {
      opacity: 1;
      transition: opacity 500ms ease;
    }
  }

  &.collapsed {
    .collapsible-content {
      opacity: 0;
      visibility: hidden;
      height: 0;
      margin: 0;
    }
  }
}
