@use "assets/styles/global" as *;

.page-title-container {
  border-bottom: 1px solid $page-title-border-color;
  padding: 2rem 0;
}

.page-title {
  font-family: $accent-font-italic;
  font-size: 44px;
  font-weight: normal;
  margin: 12px 0;
  text-align: center;
  width: 100%;
  white-space: pre-wrap;

  color: $page-title-color;
}

.page-subtitle {
  width: 100%;
  text-align: center;
  white-space: pre-wrap;
  margin: 1rem 0 0 0;
  min-height: 1rem;

  color: $page-title-color;
}
