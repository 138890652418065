@use "assets/styles/global" as *;

.checkout-page .inner {
  position: relative;
}

.checkout-page .page-view-content {
  & > a {
    align-items: center;
    font-style: normal;
    font-weight: normal;
    display: flex;
    left: 2rem;
    position: absolute;
    text-decoration: none;
    top: 2rem;

    .icon {
      display: block;
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .content-wrapper {
    margin: auto;
    min-width: 800px;
    padding-top: 5rem;
    width: 50%;
  }
}

.checkout-content {
  .content-section {
    margin-bottom: 1rem;
  }

  .content-section:not(:first-child),
  .delivery-method-section {
    border-top: 1px solid $checkout-border-color;
  }

  .payment-section {
    margin-bottom: 1rem;
  }

  .orderlist {
    margin-bottom: 1rem;
    padding: 0;

    .orderlist-item {
      background-color: white;
      font-size: 14px;
      margin-top: 2px;
      padding: 14px 12px;
    }

    .item-name-number {
      display: flex;
      flex-grow: 1;
      max-width: 50%;
    }
  }

  .basket-total {
    background-color: $checkout-total-background-color;
  }

  .checkbox-container {
    .checkbox-label {
      align-items: center;
      justify-content: flex-end;
    }
  }

  .input-form {
    display: grid;
    grid-template-columns: 1fr;
  }

  .date-and-time-pickers .datepicker-section {
    margin-right: 1rem;
  }
}

.content-body.order {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 3rem;

  .spinner {
    bottom: auto;
    flex-shrink: 0;
    left: auto;
    position: relative;
    right: auto;
    top: auto;
  }
}
