@use "assets/styles/global" as *;

.checkbox-container {
  margin: 1rem 0;

  .checkbox-label {
    display: inline-flex;
    font-size: 14px;
    white-space: pre-wrap;
    cursor: pointer;
  }

  .checkbox {
    cursor: pointer;
    margin: 0.5rem 1rem;
  }
}
