@use "assets/styles/global" as *;

.minicart {
  background-color: transparent;
  font-family: $accent-font-italic;
  background-size: 0px;
  padding: 0;
  margin-left: 16px;

  min-width: 2rem;
  min-height: 100%;
  height: 32px;

  border: none;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  position: relative;

  min-width: 5rem;
  min-height: 44px;
  margin-left: 1.2rem;

  &:before {
    content: "";
    position: absolute;
    height: 50px;
    width: 45px;
    left: auto;
    bottom: 0;

    background-image: url("https://billedarkiv.blob.core.windows.net/images/376dc013-3973-4581-816a-c4b1be1fce29");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  &.click-allowed {
    &:before {
      left: auto;
    }
  }

  .ordernumber {
    position: absolute;
    text-align: center;
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: bold;
    text-indent: 0;

    -moz-border-radius: 50%;
    border-radius: 50%;

    background-color: $minicart-bubble-color;
    color: $minicart-bubble-text-color;

    width: 28px;
    height: 28px;
    line-height: 28px;
    top: -16px;
    margin-left: 20px;
  }

  &:focus {
    outline: none;
  }
}
