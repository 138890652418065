@use "assets/styles/global" as *;

.basket-total {
  background-color: $basket-total-background-color;
  box-sizing: border-box;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  justify-content: space-between;
  padding: 1rem;
  padding-bottom: 2em;
  text-transform: uppercase;
  width: 100%;
}
