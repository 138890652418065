@use "assets/styles/global" as *;

.pause-screen {
  align-items: center;
  background-color: $background-color;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $pause-screen-z-index;

  .logo {
    max-height: 8rem;
    max-width: 70%;
    padding-bottom: 4rem;
  }

  h1 {
    font-size: 70px;
    max-width: 100%;
  }

  p {
    font-family: $secondary-font;
    font-size: 30px;
    font-style: italic;
    max-width: 100%;
  }
}
