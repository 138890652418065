/* Icons */

.collapsible-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("https://billedarkiv.blob.core.windows.net/images/bbad43d7-a093-4fec-af1b-b8053bbc442d");
  background-size: cover;
  background-repeat: no-repeat;
}

.exit-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("https://billedarkiv.blob.core.windows.net/images/e56ee19f-504d-4598-9757-7eb578625dc0");
  background-size: cover;
  background-repeat: no-repeat;
}

.exit-white-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("../img/icons/exit_white.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

.left-icon {
  background-image: url("../img/icons/left.svg");
  background-size: 25%;
  background-position: 44% 50%;
  background-repeat: no-repeat;
}

.count-btn {
  &.minus {
    background-image: url("https://billedarkiv.blob.core.windows.net/images/1041879b-6c22-49dc-bac8-b0299c8c91b7");
  }

  &.plus {
    background-image: url("https://billedarkiv.blob.core.windows.net/images/ba57fb82-508a-4132-8738-db0120664fde");
  }
}
