html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $text-color;
  background-color: $background-color;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;

  touch-action: manipulation;

  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: $primary-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  font-weight: bold;
  color: $text-color;
}

button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: $primary-font;

  &:focus {
    outline: none;
  }
}

.button {
  text-align: center;
  font-size: 1rem;

  &:hover {
    cursor: pointer;
  }

  &.button-muted {
    background: none;
    border: none;
    color: $muted-button-text-color;
    font-family: $secondary-font;
    font-size: 1rem;
    font-weight: 400;
  }

  &.button-colored {
    border-radius: 0;
    border-style: solid;
    border-width: 2px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 16px 20px;
    transition: color 0.2s, background-color 0.2s;

    &.red {
      background-color: $primary-color;
      border-color: $primary-color;

      &:hover:not(:disabled) {
        color: $primary-color;
      }
    }

    &.green {
      background-color: $secondary-color;
      border-color: $secondary-color;

      &:hover:not(:disabled) {
        color: $secondary-color;
      }
    }

    &.gray {
      background-color: $muted-button-color;
      border-color: $muted-button-color;

      &:hover:not(:disabled) {
        color: $muted-button-color;
      }
    }

    &:hover:not(:disabled) {
      background-color: transparent;
    }
  }
}

.button:disabled,
.button[disabled] {
  cursor: auto;
  opacity: 0.6;

  &:hover {
    cursor: auto;
  }
}
