@use "assets/styles/global" as *;

.category-grid {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 1rem;
  margin-top: 1rem;
}

.gridbox {
  animation-duration: 500ms;
  animation-name: fadein;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &:first-child {
    grid-column: span 2 / span 2;

    .gridbox-image,
    .gridbox-title {
      background-color: $grid-promote-background-color;
      color: $grid-promote-text-color;
    }
  }

  .gridbox-image {
    height: 220px;
    width: 100%;

    background-color: $grid-background-color;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .gridbox-title {
    background-color: $grid-background-color;
    color: $grid-text-color;
    flex-grow: 1;
    font-size: 1.5rem;
    margin: 0;
    min-height: 4rem;
    overflow-wrap: normal;
    padding: 1.5rem;
    text-align: left;
    min-height: 3rem;
    overflow-wrap: break-word;
  }
}
