/********* Colors *********/
$primary-color: #b8292f;
$primary-color-dark: #78191d;
$secondary-color: #64b544;

$background-color: #f5f3ec;
$box-shadow-color: rgba(0, 0, 0, 0.1);
$border-color: #d4d1c2;
$error-color: #b8292f;

$input-color: white;
$input-text-color: $primary-color;
$input-placeholder-text-color: #303030;
$textarea-text-color: #303030;

$label-background-color: #af272f;
$label-text-color: white;

$text-color: #303030;
$page-title-color: #303030;
$page-title-border-color: #d4d1c2;

$grid-background-color: white;
$grid-text-color: #484848;
$grid-promote-background-color: $primary-color;
$grid-promote-text-color: white;

$product-background-color: white;
$product-title-color: $primary-color;
$product-subtitle-color: $primary-color-dark;
$product-text-color: #636159;
$product-active-button-background-color: $primary-color-dark;
$product-active-button-border-color: $primary-color-dark;
$product-modifier-background-color: #edeade;
$product-modifier-border-color: #d4d1c2;
$product-no-modifier-price-text-color: $primary-color-dark;
$product-counter-number-text-color: #000000;
$product-active-counter-number-text-color: $primary-color;
$product-sale-price-text-color: $primary-color-dark;

$modifier-title-text-color: $primary-color;
$modifier-counter-text-color: #d4d1c2;
$modifier-extra-choice-text-color: $primary-color;

$choice-background-color: white;
$choice-active-text-color: $primary-color;

$precheckout-products-background-color: #edeade;
$precheckout-products-text-color: #484848;
$precheckout-products-active-text-color: $primary-color;

$checkout-title-text-color: $primary-color;
$checkout-title-count-text-color: #d4d1c2;
$checkout-border-color: #d4d1c2;
$checkout-total-background-color: #edeade;
$checkout-pickup-title-text-color: $primary-color;
$checkout-inline-pickup-background-color: $primary-color;
$checkout-inline-pickup-text-color: white;

$order-title-text-color: $primary-color;

$button-color: #ececec;
$button-text-color: #303030;
$accent-button-color: #ececec;
$accent-button-text-color: #303030;
$muted-button-color: #e5e3da;
$muted-button-text-color: #e5e3da;
$clear-order-button-background-color: #cecece;
$clear-order-button-color: #303030;
$muted-payment-button-color: #d4d1c2;

$header-background-color: #edeade;

$minicart-bubble-color: #303030;
$minicart-bubble-text-color: white;

$expanded-cart-background-color: $header-background-color;
$expanded-cart-border-color: #d4d1c2;

$summarycart-background-color: #d4d1c2;
$summarycart-active-background-color: #b3aea4;

$sidebar-background-color: $primary-color;
$sidebar-active-background-color: $primary-color-dark;
$sidebar-text-color: white;
$sidebar-border-color: #dddddd;
$sidebar-accent-background-color: #cd2e34;
$sidebar-accent-text-color: white;

$counter-background-icon: $primary-color;
$counter-checkout-background-icon: #000000;
$counter-hover-background-icon: $primary-color;
$counter-icon-color: white;
$counter-text-color: #303030;

$timepicker-background-color: #edeade;
$timepicker-even-row-background-color: #e6e2d1;
$timepicker-text-color: #303030;

$basket-total-background-color: #d4d1c2;
$simple-total-border-color: #d4d1c2;

$spinner-background: rgba(48, 60, 55, 0.15);
$spinner-color: rgba(48, 60, 55, 0.8);

$terms-iframe-background: rgba(0, 0, 0, 0.8);

/********* Fonts *********/
$primary-font: Arvo, serif;
$secondary-font: Roboto, sans-serif;
$accent-font-italic: Lobster, cursive;
$accent-font: "Lobster Two", cursive;

/********* Z-indexes *********/
$topbar-z-index: 1;
$input-z-index: 2;
$header-z-index: 10;
$spinner-z-index: 100;
$iframe-z-index: 101;
$pause-screen-z-index: 9999;
