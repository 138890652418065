@use "assets/styles/global" as *;

.restaurant-info {
  align-items: center;
  display: flex;
  justify-content: center;
  gap: 5px;

  .restaurant-phone {
    text-transform: uppercase;
  }
}
