@use "assets/styles/global" as *;

.labels {
  display: flex;
  align-items: center;
  padding: 0 1rem;

  .label {
    .label-icon {
      margin: 0 2px;
    }
  }

  .info-label {
    position: relative;
    border-radius: 50%;
    background-color: $label-background-color;
    color: $label-text-color;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    margin: 0 2px;
    cursor: pointer;

    .info-box {
      display: none;
      background: black;
      color: white;
      padding: 0.5rem 1rem;
      position: absolute;
      top: 18px;
      left: 18px;
      width: 10rem;

      .info-box-text {
        margin: 0.5rem 0;

        .label-description {
          font-style: italic;
          font-size: 10px;
        }
      }
    }

    &:hover {
      .info-box {
        display: block;
      }
    }
  }
}
