@use "assets/styles/global" as *;

.product-modifier-container {
  animation-duration: 500ms;
  animation-name: fadein;
  background-color: $product-modifier-background-color;
  padding: 1rem;

  & > .modifier + .modifier {
    border-top: 1px solid $product-modifier-border-color;
  }

  .modifier {
    position: relative;
    padding: 1rem 0 0.75rem 0;
  }

  .modifier-title {
    align-items: baseline;
    color: $modifier-title-text-color;
    font-family: $accent-font-italic;
    font-weight: normal;
    font-size: 26px;
    display: flex;
    flex-direction: row;
    padding-right: 2rem;
    position: relative;

    .modifier-counter {
      color: $modifier-counter-text-color;
      font-family: $accent-font;
      font-size: 36px;
      line-height: 1.05;
      margin-right: 20px;
    }

    .collapsible-icon {
      position: absolute;
      right: 0;
    }
  }

  .single-missing-choices-warning {
    color: $error-color;
    display: block;
    font-size: 0.8em;
    font-weight: bold;
    margin: 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }

  .collapsed-text {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .collapsed-chosen-options,
    .collapsed-price {
      margin: 0.5rem 0;
      font-size: 15px;
      font-style: italic;
    }

    .collapsed-chosen-options {
      max-width: 80%;
    }

    .collapsed-price {
      right: 0;
    }
  }
}

.product-modifier-footer {
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1rem;
  }

  .missing-choices-warning {
    position: relative;

    font-family: $secondary-font;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;

    color: $error-color;
  }

  .product-cta-buttons {
    display: flex;
    flex-direction: column;

    button {
      font-size: 18px;
      padding: 18px;  
    }
  }

  .dismiss-container {
    border-top: 1px solid $expanded-cart-border-color;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding-top: 1rem;
    width: 100%;

    .dismiss-button {
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      cursor: pointer;
      height: 14px;
      padding: 0.5rem;
      width: 24px;
    }
  }
}

// Handling collapsed/expanded modifier

.modifier {
  &.expanded {
    .single-missing-choices-warning,
    .collapsed-text {
      display: none;
    }
  }

  &.collapsed {
    .choice-list {
      margin: 0;

      .choice {
        display: none;
      }
    }
  }

  footer {
    align-items: center;
    display: flex;
    font-weight: 700;
    justify-content: space-between;

    p {
      margin: 0;
    }

    .extra-choice-text {
      font-size: 12px;
      color: $modifier-extra-choice-text-color;
    }

    .extra-choice-price {
      font-size: 16px;
    }
  }
}

.quantity-modifier {
  footer {
    justify-content: flex-end;
  }
}
