@use "assets/styles/global" as *;

.confirmation-content {
  padding-top: 2rem;

  .confirmation-text-title {
    color: $order-title-text-color;
  }

  .restaurant-info .location-info-text {
    .location-name {
      display: block;
      font-weight: bold;
    }

    .location-email,
    .location-cvr {
      display: none;
    }
  }

  .order-section {
    padding: 0 2rem;
  }

  .order-title {
    margin: 1.5rem 0;
  }

  .orderlist .list-item {
    padding-bottom: 1rem;
  }

  .total-title,
  .total-amount {
    font-weight: normal;
    font-size: 24px;
  }

  footer {
    border-top: 1px solid $page-title-border-color;
    margin-top: 2rem;
  }
}

.confirmation-text-title {
  font-weight: bold;
}
