@use "assets/styles/global" as *;

.insights-operation-id {
  font-size: 12px;
  opacity: 0.4;
  margin: -0.5rem 0 0 0;
}

.error-message-content {
  text-align: center;

  .restaurant-info {
    margin: 2rem 0;

    .location-info-text {
      span {
        display: none;
      }

      span.location-phone {
        display: block;
      }

      span.location-name {
        display: block;
        font-weight: bold;
      }
    }
  }
}
