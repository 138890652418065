@use "assets/styles/global" as *;

.header-container {
  background-color: $header-background-color;
  flex-direction: column;
  display: flex;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  position: relative;
  z-index: $header-z-index;
  width: 100%;

  &.expanded-header-container {
    height: 100vh;
    height: auto;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}

.page-view > .logo-container {
  z-index: 100;
  position: relative;

  .logo {
    height: 100px;
    position: absolute;
    top: 35px;
    width: 90px;
  }
}
