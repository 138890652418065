@use "assets/styles/global" as *;
@import "assets/styles/base";

.checkout-content {
  .orderlist-item {
    grid-template-columns: 1fr 4fr 1fr;
    column-gap: 0.75rem;
    row-gap: 0.25rem;
    padding: 0.5rem 0;
    position: relative;

    &.with-choices {
      row-gap: 0.5rem;
    }

    .button-muted {
      @extend .button, .button-colored, .gray;

      font-family: $primary-font;
      font-size: 16px;
      left: 70%;
      padding: 8px 16px;
      position: absolute;
      text-transform: uppercase;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.footer {
  display: none;
  justify-content: flex-end;

  button {
    margin-top: 2rem;
  }
}
