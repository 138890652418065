@use "assets/styles/global" as *;

.expanded-cart {
  background-color: $expanded-cart-background-color;
  border-top: 1px solid $expanded-cart-border-color;
  overflow: hidden;

  transform: translate3d(0, 0, 0); // iOS Safari hack
  animation-duration: 500ms;
  animation-name: mobile-navigation-fadein;
  max-height: 100vh;
  max-height: -webkit-fill-available;

  width: 100%;

  &.closing {
    animation-name: mobile-navigation-fadeout;
    max-height: 0;
  }

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    padding-top: 4rem;

    & > * + * {
      margin-top: 1rem;
    }

    h1 {
      font-family: $accent-font-italic;
      font-size: 48px;
      font-weight: normal;
      margin-bottom: 1rem;
    }

    .clear-order-button {
      align-self: flex-end;
      background-color: $clear-order-button-background-color;
      border-radius: 6px;
      border: none;
      color: $clear-order-button-color;
      font-family: $secondary-font;
      font-size: 14px;
      opacity: 0.7;
      padding: 4px 11px;
    }

    .orderlist {
      background-color: white;
    }

    .cart-cta-button {
      button {
        width: 200px;
      }
    }

    .footer {
      border-top: 1px solid $expanded-cart-border-color;
      display: flex;
      justify-content: center;
      overflow: hidden;
      padding-top: 1rem;
      width: 100%;

      button {
        background-color: transparent;
        background-repeat: no-repeat;
        background-size: contain;
        border: none;
        cursor: pointer;
        height: 14px;
        padding: 0.5rem;
        width: 24px;
      }
    }
  }
}
