@use "assets/styles/global" as *;

.login-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100vh;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 580px;

    .input-item {
      margin: 0;
    }

    .error {
      color: $error-color;
      font-family: $secondary-font;
      font-size: 16px;
      font-weight: 700;
      text-align: center;
    }

    button {
      align-self: flex-end;
      margin-top: 1rem;
    }
  }
}
